import React from 'react';
import PropTypes from 'prop-types';
import './AboutUsSectionComponent.scss';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row'
import {translate} from 'react-switch-lang';

const AboutUsSectionComponent = ({text}) => (
  <div className="AboutUsSectionComponent circles-background">
    <Row className="row website-content">
      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <h1 className="title">
          <span>
            <img className="left_arrow" alt="InvertedCode llave" src={process.env.PUBLIC_URL+'/assets/about_us/llave_blanca.svg'}></img>
          </span>
          &nbsp;{text('aboutUs.title')}&nbsp;
          <span> 
          <img className="right_arrow" alt="InvertedCode llave" src={process.env.PUBLIC_URL+'/assets/about_us/llave_blanca.svg'}></img>
          </span>
        </h1>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <div className="title2">
          {text('aboutUs.description')}
        </div>
      </Col>
      <Col xs={12} sm={12} md={6} lg={6} xl={6} className="left-description-col">
        <Row className="row align-items-center left-description-row">
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <span>
              <img className="img" alt="InvertedCode personas" src={process.env.PUBLIC_URL+'/assets/about_us/personas_blancas.svg'}></img>
            </span>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="subtitles">{text('aboutUs.team_title')}</div>
            </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="description">
              {text('aboutUs.team_description')}
            </div>
          </Col>
        </Row>
      </Col>
      <Col xs={12} sm={12} md={6} lg={6} xl={6} className="right-description-col">
        <Row className="row align-items-center right-description-row">
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <span>
              <img className="img" alt="InvertedCode Engrane" src={process.env.PUBLIC_URL+'/assets/about_us/engrane_blanco.svg'}></img>
            </span>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="subtitles">
              {text('aboutUs.skills_title')}
            </div>
            </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="description">
              {text('aboutUs.skills_description')}
            </div>
            </Col>
        </Row>
      </Col>
    </Row>
  </div>
);

AboutUsSectionComponent.propTypes = {
  text: PropTypes.func.isRequired
};

AboutUsSectionComponent.defaultProps = {};

export default translate(AboutUsSectionComponent);