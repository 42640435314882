import React from 'react';
import PropTypes from 'prop-types';
import NavBar from '../NavBarComponent/NavBarComponent';
import HeroSection from '../HeroSectionComponent/HeroSectionComponent';
import AboutUs from '../AboutUsSectionComponent/AboutUsSectionComponent';
import OurServices from '../OurServicesSectionComponent/OurServicesSectionComponent';
import PortfolioComponent from '../PortfolioComponent/PortfolioComponent';
import Contact from '../ContactSectionComponent/ContactSectionComponent';
import Footer from '../FooterSectionComponent/FooterSectionComponent';
import { translate } from 'react-switch-lang';
import './Home.scss';

const Home = ({text, language, changeLanguage}) => (
  <div className="Home" id="home">
    <NavBar text={text} language={language} changeLanguage={changeLanguage}/>
    <HeroSection text = {text}/>
    <AboutUs text = {text}/>
    <OurServices text = {text}/>
    {/*
      <PortfolioComponent language={language} text={text}/>
    */}
    <Contact language={language} text = {text}/>
    <Footer text = {text}/>
    <a className="wa-float" target="_blank" href={"https://wa.me/5215529079737?text=" + text('whatsapp.message')}><img src={process.env.PUBLIC_URL+'/assets/Home/whatsapp.svg'} alt="Contacto de whatsapp"></img></a>
  </div>
);

Home.propTypes = {
  text: PropTypes.func.isRequired
};

Home.defaultProps = {};

export default translate(Home);