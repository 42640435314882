import React from 'react';
import PropTypes from 'prop-types';
import './OurServicesSectionComponent.scss';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row'
import { translate } from 'react-switch-lang';

const OurServicesSectionComponent = ({text}) => (
  <div className="OurServicesSectionComponent" id="services">
    <Row className="website-content">
      <div className="content">
        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="title">
          <h2>
            {text('ourServices.title')}
          </h2>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <h1 className="about-us-title">
            {text('ourServices.description')}
          </h1>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Row className="our-services">
            <Col xs={12} sm={6} md={6} lg={3} xl={3}>
              <span>
                <img className="img" alt="InvertedCode brackets" src={process.env.PUBLIC_URL+'/assets/services/llave_azul.svg'}></img>
              </span>
              <h1 className="title">
                {text('ourServices.dev_title')}
              </h1>
              <p>{text('ourServices.dev_description_1')}</p>
              <p>{text('ourServices.dev_description_2')}</p>
              <p>{text('ourServices.dev_description_3')}</p>
              <p>{text('ourServices.dev_description_4')}</p>
            </Col>
            <Col className="downside2" xs={12} sm={6} md={6} lg={3} xl={3}>
              <span>
                <img className="img" alt="InvertedCode design" src={process.env.PUBLIC_URL+'/assets/services/laptop_azul.svg'}></img>
              </span>
              <h1 className="title">
                {text('ourServices.design_title')}
              </h1>
              <p>{text('ourServices.design_description_1')}</p>
              <p>{text('ourServices.design_description_2')}</p>
              <p>{text('ourServices.design_description_3')}</p>
            </Col>
            <Col className="downside" xs={12} sm={6} md={6} lg={3} xl={3}>
              <span>
                <img className="creation-img" alt="InvertedCode creation" src={process.env.PUBLIC_URL+'/assets/services/browser_azul.svg'}></img>
              </span>
              <h1 className="title">
                {text('ourServices.creation_title')}
              </h1>
              <p>{text('ourServices.creation_description_1')}</p>
              <p>{text('ourServices.creation_description_2')}</p>
              <p>{text('ourServices.creation_description_3')}</p>
            </Col>
            <Col className="downside" xs={12} sm={6} md={6} lg={3} xl={3}>
              <span>
                <img className="packages-img" alt="InvertedCode packages" src={process.env.PUBLIC_URL+'/assets/services/videogame_azul.svg'}></img>
              </span>
              <h1 className="title">
                {text('ourServices.packages_title')}
              </h1>
              <p>{text('ourServices.packages_description_1')}</p>
              <p>{text('ourServices.packages_description_2')}</p>
            </Col>
          </Row>
        </Col>
      </div>
    </Row>
  </div>
);

OurServicesSectionComponent.propTypes = {
  text: PropTypes.func.isRequired
};

OurServicesSectionComponent.defaultProps = {};

export default translate(OurServicesSectionComponent);