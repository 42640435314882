import React, { useState, useEffect } from 'react';
import './NavBarComponent.scss';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import PropTypes from 'prop-types';
import { Link } from 'react-scroll'
import {translate} from 'react-switch-lang';

const NavBarComponent = ({text, language, changeLanguage}) => {
  const [show, setShow] = useState(true);
  const [previousScroll, setPreviousScroll] = useState(0);
  const [currentScroll, setCurrentScroll] = useState(-1);

  let handleScroll = (e) => {
    setCurrentScroll(e.target.documentElement.scrollTop)
  }

  useEffect(() => {
    window.addEventListener('scroll',handleScroll)

    return () => window.removeEventListener('scroll',handleScroll)
  },[]);
  
  useEffect(() => {
    setShow(previousScroll > currentScroll || currentScroll <= 0);
    setPreviousScroll(currentScroll)
  },[currentScroll]);

  return (
    <Navbar className={show? '' : 'hide'} bg="white" expand="md" sticky="top">
      <Navbar.Brand>
        <Link to="hero" smooth={true} duration={600}>
          <img className="header_logo" alt="InvertedCode logo" src={process.env.PUBLIC_URL+'/assets/logo/logo_main.png'}></img>
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          {language === 'esp'? 
              <Link className="navbar-links" to="services" smooth={true} duration={600}>
                Serv<span className="inverted-i">i</span><span className="inverted-c">c</span><span className="inverted-i">i</span>os
              </Link> :
              <Link className="navbar-links" to="services" smooth={true} duration={600}>
                Serv<span className="inverted-i">i</span><span className="inverted-c">c</span>es
              </Link>
          }
          {language === 'esp'? 
              <Link className="navbar-links" to="contact_us" smooth={true} duration={600}>
                <span className="inverted-c">c</span>ontá<span className="inverted-c">c</span>tanos
              </Link> :
              <Link className="navbar-links" to="contact_us" smooth={true} duration={600}>
                <span className="inverted-c">c</span>onta<span className="inverted-c">c</span>t us
              </Link>
          }
          <Nav.Link onClick={changeLanguage} className="navbar-links language-change"><span className={language === 'en'? 'active-language' : ''}>EN</span>/<span className={language === 'esp'? 'active-language' : ''}>ESP</span></Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

NavBarComponent.propTypes = {
  text: PropTypes.func.isRequired
};

export default translate(NavBarComponent);
