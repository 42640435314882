import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './ContactSectionComponent.scss';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button';
import { translate } from 'react-switch-lang';
import axios from 'axios';

const ContactSectionComponent = ({text, language}) => {
  const [inputs, setInputs] = useState(
    {
      name: '',
      city: '',
      project: '',
      phone: '',
      email: ''
    }
  );

  let setInputWidths = () => {
    let input = document.querySelectorAll('input');
    for(let i=0; i<input.length; i++){
      if(input[i].getAttribute('value') !== ''){
        input[i].setAttribute('size',input[i].getAttribute('value').length + 3);
      }else{
        input[i].setAttribute('size',input[i].getAttribute('placeholder').length);
      }
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setInputWidths()
    },200)
  },[]);

  useEffect(() => {
    setTimeout(() => {
      setInputWidths()
    },200)
  },[language]);

  const handleInputChange = (e) => {
    let key = e.target.name
    let value = e.target.value
    if(key == 'phone'){
      value = value.replace(/-/g,'');
      value = value.replace(/\ /g,'');
    }
    setInputs(
      {
        ...inputs,
        [key]: value
      }
    )
    setInputWidths();
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    // validation
    let valid = false;
    let validationMessage = text('contact.validation_required');

    // required
    if(inputs.name === '' || inputs.city === '' || inputs.project === '' || inputs.phone === '' || inputs.email === ''){
      validationMessage = text('contact.validation_required');
    }else{
      // email
      const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

      if(re.test(inputs.email)){
        // phone
        const re = /^\+?[0-9]+$/i;

        if(re.test(inputs.phone)){
          valid = true;
        }else{
          validationMessage = text('contact.validation_phone');
        }
      }else{
        validationMessage = text('contact.validation_email');
      }
    }

    // form is valid
    if(valid){
      axios({
        method: 'POST',
        url: 'https://www.api.invertedcode.co/api/sendmail',
        data: {
          text: `${inputs.name} from ${inputs.city} wants to build a(n) ${inputs.project}! Contact him/her at ${inputs.phone} or ${inputs.email}.`,
          token: process.env.REACT_APP_API_TOKEN
        },
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res => {
        alert(text('contact.message_sent'))
      }, rej => {
        alert(text('contact.message_error'))
      }).catch(err => {
        alert(text('contact.message_error_2'))
      })

    }else{
      alert(validationMessage)
    }
  }

  return (
    <div id="contact_us" className="ContactSectionComponent">
      <Row className="row website-content">
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <h1 className="title">{text('contact.title')}</h1>
        </Col>
        <Col className="subtitle-col" xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className="title2">{text('contact.description')}</div>
        </Col>
      </Row>
      <Row className="row form-row website-content">
        <form method="post">
          <Col className="form-text" xs={12} sm={12} md={12} lg={12} xl={12}>
            <Row className="form-text-1">
              <p className="d-inline-block contact-us-text">{text('contact.text1')}</p>
              <input required name="name" onChange={handleInputChange} value={inputs.name} className="contact-inputs" type="text" placeholder={text('contact.place1')}></input>
              <p className="d-inline-block contact-us-text">{text('contact.text2')}</p>
              <input required name="city" onChange={handleInputChange} value={inputs.city} className="contact-inputs" type="text" placeholder={text('contact.place2')}></input>
              <p className="d-inline-block contact-us-text">{text('contact.text3')}</p>
              <input required name="project" onChange={handleInputChange} value={inputs.project} className="contact-inputs" type="text" placeholder={text('contact.place3')}></input>
            </Row>
            <Row className="form-text-2">
              <p className="d-inline-block contact-us-text">{text('contact.text4')}</p>
              <input required name="phone" onChange={handleInputChange} value={inputs.phone} className="contact-inputs" type="text" placeholder={text('contact.place4')}></input>
              <p className="d-inline-block contact-us-text">{text('contact.text5')}</p>
              <input required name="email" onChange={handleInputChange} value={inputs.email} className="contact-inputs" type="text" placeholder={text('contact.place5')}></input>
            </Row>
          </Col>
          <Col className="button-col" xs={12} sm={12} md={12} lg={12} xl={12}>
            <Button onClick={handleSubmit} className="submit-button" type="submit">{text('contact.button')}</Button>
          </Col>
        </form>
      </Row>
    </div>
  );
}

ContactSectionComponent.propTypes = {
  text: PropTypes.func.isRequired
};

ContactSectionComponent.defaultProps = {};

export default translate(ContactSectionComponent);