import React from 'react';
import PropTypes from 'prop-types';
import './HeroSectionComponent.scss';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row'
import {translate} from 'react-switch-lang';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-scroll'

const HeroSectionComponent = ({text}) => (
  <div className="HeroSectionComponent large-background">
      <Row noGutters={true} className="website-content">
        <Col xl={6} lg={12} md={12} className="left_side">
          <div className="hero_content large-background">
            <h1 className="hero_tittle">{text('heroSection.title')}</h1>
            <div className="hero_description">{text('heroSection.description')}</div>
            <Link className="navbar-links" to="contact_us" smooth={true} duration={600}>
              <Button className="hero_button" size="lg">{text('heroSection.button')}</Button>
            </Link>
          </div>
        </Col>
        <Col xl={6} lg={0} className="right_side">
          <img className="telephones_image" alt="InvertedCode phones" src={process.env.PUBLIC_URL+'/assets/Hero/telefonos_hero.svg'}></img>
        </Col>
      </Row>
  </div>
);

HeroSectionComponent.propTypes = {
  text: PropTypes.func.isRequired
};

HeroSectionComponent.defaultProps = {};

export default translate(HeroSectionComponent);
