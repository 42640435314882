import React, { useState, useEffect } from 'react';
import './App.scss';
import Home from './components/Home/Home';
import PortfolioShow from './components/PortfolioShowComponent/PortfolioShowComponent';
import PropTypes from 'prop-types';
import {setTranslations,setDefaultLanguage,setLanguageCookie,setLanguage,translate} from 'react-switch-lang';
import en from './lang/en.json';
import esp from './lang/esp.json';
import { useRouteMatch, Route, Switch } from 'react-router-dom';

function App(props) {
  const match = useRouteMatch();
  const[lan , setLan] = useState('esp');
  let { t } = props;

  useEffect(() => {
    setTranslations({ en, esp });
    setDefaultLanguage('esp');
    setLanguageCookie();
  }, []);

  useEffect(() => {
    setLanguage(lan);
  }, [lan]);

  let changeLanguage = () => {
    lan === 'esp'? setLan('en') : setLan('esp')
  }

  return (
    <>
      <div className="App" id="hero">
        <Switch>
          <Route exact path={`${match.url}`}>
            <Home text={t} language={lan} changeLanguage={changeLanguage}/>
          </Route>
          {/*
          <Route exact path={`${match.url}portfolio-show`}>
            <PortfolioShow text={t} language={lan} changeLanguage={changeLanguage}/>
          </Route>
          */}
          <Route path={'*'}>
            <Home text={t} language={lan} changeLanguage={changeLanguage}/>
          </Route>
        </Switch>
      </div>
    </>
  );}

App.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate(App);