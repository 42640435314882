import React from 'react';
import PropTypes from 'prop-types';
import './FooterSectionComponent.scss';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row'
import {translate} from 'react-switch-lang';

const FooterSectionComponent = ({text}) => (
  <div className="FooterSectionComponent">
    <Row className="background-grey h-100 website-content">
      <Col xs={12} sm={12} md={12} lg={6} xl={6} className="SocialImgs">
        <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/InvertedCode"><span> <img alt="InvertedCode logo" src={process.env.PUBLIC_URL+'/assets/footer/facebook.svg'}></img></span></a>
        <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UC-GZSMeon11O2fsLovYS-ZQ/featured"><span> <img alt="InvertedCode logo" src={process.env.PUBLIC_URL+'/assets/footer/youtube.svg'}></img></span></a>
        <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/invertedcode"><span><img alt="InvertedCode logo" src={process.env.PUBLIC_URL+'/assets/footer/linkedin.svg'}></img></span></a>
        <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/invertedcode/"><span><img alt="InvertedCode logo" src={process.env.PUBLIC_URL+'/assets/footer/instagram.svg'}></img></span></a>
      </Col>
      <Col className="contact-info" xs={12} sm={12} md={12} lg={6} xl={6}>
        <div>
          <div className="d-inline-block contact-data contact-data-1">
            <img className="footer-contact-icons footer-icons d-inline-block mx-4" alt="email icon" src={process.env.PUBLIC_URL+'/assets/footer/mail.svg'}></img>
            <a target="_blank" rel="noopener noreferrer" className="d-inline-block footer-contact-links" href="mailto:contacto@invertedcode.co">
              <p>contacto@invertedcode.co</p>
            </a>
          </div>
          <div className="d-inline-block contact-data contact-data-2">
            <img className="footer-contact-icons footer-icons d-inline-block mx-4" alt="phone icon" src={process.env.PUBLIC_URL+'/assets/footer/phone.svg'}></img>
            <a target="_blank" rel="noopener noreferrer" className="d-inline-block footer-contact-links" href="https://wa.me/5215529079737">
              <p className="phone-number">55 2907 9737</p>
            </a>
          </div>
        </div>
      </Col>
    </Row>
  </div>
);

//<a href=""><span><img alt="InvertedCode logo" src={process.env.PUBLIC_URL+'/assets/footer/youtube.svg'}></img></span></a>

FooterSectionComponent.propTypes = {
  text: PropTypes.func.isRequired
};

FooterSectionComponent.defaultProps = {};

export default translate(FooterSectionComponent);