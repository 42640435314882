import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './PortfolioComponent.scss';
import Row from 'react-bootstrap/Row'
import {translate} from 'react-switch-lang';
import Carousel from 'react-bootstrap/Carousel'
import * as englishReviews from '../../lang/en.json';
import * as spanishReviews from '../../lang/esp.json';

const reviewsEN = englishReviews.default.portfolio.testimonials;
const reviewsESP = spanishReviews.default.portfolio.testimonials;

const PortfolioComponent = ({text, language}) => {
  const [reviews, setReviews] = useState([])

  let setReviewsLanguage = () => {
    language === 'en'? setReviews(reviewsEN) : setReviews(reviewsESP)
  }
  
  useEffect(() => {
    setReviewsLanguage()
  },[])

  useEffect(() => {
    setReviewsLanguage()
  },[language])
  
  return (
    <div id="portfolio" className="PortfolioComponent">
      <Row className="portfolio-background website-content circles-background">
        <Carousel fade={true} controls={false} interval={2000} className="w-100 text-center">
          {
            reviews.map((r, i) => {
              return (
                <Carousel.Item key={i}>
                  <div className="quote-container d-block w-100">
                    <div className="quote-divider quote-divider-left">
                      <span className="quotation-mark">
                        “
                      </span>
                    </div>
                    <div className="quote-divider quote-divider-right">
                      <h1 className="quote">
                        {r.quote}
                      </h1>
                    </div>
                  </div>
                  <Carousel.Caption className="text-left w-100">
                    <p className="author author-name">{r.name}</p>
                    <p className="author author-title">{r.title}</p>
                    <p className="">{text('portfolio.more')}</p>
                  </Carousel.Caption>
                </Carousel.Item>
              );
            })
          }
        </Carousel>
      </Row>
    </div>
  );
}

PortfolioComponent.propTypes = {
  text: PropTypes.func.isRequired
};

export default translate(PortfolioComponent);