import React from 'react';
import PropTypes from 'prop-types';
import NavBar from '../NavBarComponent/NavBarComponent';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row'
import { translate } from 'react-switch-lang';
import './PortfolioShowComponent.scss';

const PortfolioShowComponent = ({text, language, changeLanguage}) => (
  <div className="PortfolioShowComponent">
    <NavBar text={text} language={language} changeLanguage={changeLanguage}/>
    <Row className="website-content">
      <Col className="title header" xs={12} sm={12} md={12} lg={12} xl={12}>
        <h1>Empresa inventada</h1>
      </Col>
      <Col className="header" xs={12} sm={12} md={12} lg={12} xl={12}>
        <Row>
          <Col xs={6} sm={6} md={4} lg={3} xl={3}>
            <p className="sub-title">{text('portfolio_show.description1')}</p>
            <p className="sub-descrip">App para video</p>
          </Col>
          <Col xs={6} sm={6} md={4} lg={3} xl={3}>
            <p className="sub-title">{text('portfolio_show.description2')}</p>
            <p className="sub-descrip">Iphone y Android</p>
          </Col>
        </Row>
      </Col>
      <Col className="content" xs={12} sm={12} md={12} lg={12} xl={12}>
        <Row>
          <Col className="img" xs={12} sm={12} md={6} lg={6} xl={6}>
            <img className="telephones_image" alt="InvertedCode article" src={process.env.PUBLIC_URL+'/assets/logo/favicon.png'}></img>
          </Col>
          <Col className="txt" xs={12} sm={12} md={6} lg={6} xl={6}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Etiam sit amet nisl purus. Dictum varius duis at consectetur. Eget dolor morbi non arcu risus quis varius. Proin nibh nisl condimentum id venenatis a condimentum. Eu feugiat pretium nibh ipsum consequat nisl. Elit ut aliquam purus sit. Elementum nibh tellus molestie nunc non blandit. Ac tortor dignissim convallis aenean et tortor at risus. Malesuada fames ac turpis egestas integer eget.
            Sem fringilla ut morbi tincidunt augue interdum velit. Fermentum iaculis eu non diam phasellus vestibulum lorem. Volutpat consequat mauris nunc congue nisi vitae suscipit tellus mauris. Duis convallis convallis tellus id interdum velit laoreet id donec..
          </Col>
        </Row>
      </Col>
    </Row>
  </div>
);

PortfolioShowComponent.propTypes = {
  text: PropTypes.func.isRequired
};

PortfolioShowComponent.defaultProps = {};

export default translate(PortfolioShowComponent);
